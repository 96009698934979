/* eslint-disable prettier/prettier */
import React from 'react';

import NoDataFound from '../assets/icons/svg/noDataImg.svg';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  noDataBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  image: {
    width: '70%',
    height: '100%',
  },
}));

interface NoAttacksFoundProps {
  id: string;
}

const NoAttacksFound: React.FC<NoAttacksFoundProps> = ({ id }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.noDataBox}
      data-testid={`${id}-noDataAvailable`}
      id={id}
    >
      <img className={classes.image} src={NoDataFound} alt="noDataAvailabel" />
    </Box>
  );
};

export default NoAttacksFound;
