import React, { useState } from 'react';
import { Popover, Divider } from '@mui/material';
import { differenceInCalendarDays, differenceInHours } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { request } from '../../../services/request';
import { getDownloadPDFApiEndpoint } from '../../../constants/endpoints';
import CONSTANTS from '../../../constants/constants';
import { useDispatch } from 'react-redux';
import FileSaver from 'file-saver';
import { setIsDownloadingPdf } from '../../../redux/loader/loadingActions';
import './customDate.css';
import { convertToISTFormat } from '../../../screen/utils/convertTime';
import CustomButton from '../customButton';
import { getSessionStorageItem } from '../../../encrypt';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../../screen/utils/notificationHelpers';
import MUIDateInput from './DateInput';
const useStyles = makeStyles((theme) => ({
  popover: {
    padding: '1rem',
    width: '30%',

    [theme.breakpoints.down(800)]: {
      width: '60%',
    },
    [theme.breakpoints.down(500)]: {
      width: '78%',
    },
    '@media (min-width:2560px)': {
      width: '24%',
    },
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  divider: {
    margin: '0 -1rem 0.1rem -1rem !important',
    backgroundColor: '#0046FF',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const savePdfFile = (dispatch, data) => {
  const blob = new Blob([data], { type: 'application/pdf' });
  FileSaver.saveAs(blob, CONSTANTS.DOWNLOADED_LOGS_FILE_NAME);
  handleSuccessNotification(dispatch, CONSTANTS.PDF_DOWNLOAD_SUCCESS_MSG);
};

export const downloadPdf = async (dispatch, DOWNLOAD_PDF_ENDPOINT) => {
  try {
    const res: any = await request.getBlob(
      `${getSessionStorageItem(CONSTANTS.GATEWAY_URL)}${DOWNLOAD_PDF_ENDPOINT}`
    );
    if (!res?.success) {
      handleErrorNotification(dispatch, CONSTANTS.PDF_DOWNLOAD_FAILURE_MSG);
    } else if (res?.data) {
      savePdfFile(dispatch, res?.data);
    }
  } catch (error: any) {
    handleErrorNotification(
      dispatch,
      CONSTANTS.PDF_DOWNLOAD_FAILURE_MSG || error?.message
    );
  }
};

const CustomDate = ({ id, anchorEl, setAnchorEl, domainId }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const customDateId = open ? id : undefined;

  const dispatch = useDispatch();

  const handleOkClick = async () => {
    if (!startDate || !endDate) return;

    setAnchorEl(null);
    const endDateTime = new Date(endDate);
    endDateTime?.setHours(23);
    endDateTime?.setMinutes(59);
    endDateTime?.setSeconds(59);

    dispatch(setIsDownloadingPdf(true));
    const daysDifference = differenceInCalendarDays(
      new Date(endDateTime),
      new Date(startDate)
    );
    const hoursDifference = differenceInHours(
      new Date(endDateTime),
      new Date(startDate)
    );

    if (daysDifference <= 0 && hoursDifference <= 0) {
      handleErrorNotification(dispatch, CONSTANTS.START_DATE_PRIOR_TO_END_DATE);
    } else if (hoursDifference > 1 && daysDifference <= 7) {
      handleSuccessNotification(dispatch, CONSTANTS.PLEASE_WAIT_MESSAGE);

      const formattedStartDate = convertToISTFormat(startDate);
      const formattedEndDate = convertToISTFormat(endDateTime);

      const startAndEndDateTime = {
        from: formattedStartDate,
        to: formattedEndDate,
      };

      const DOWNLOAD_PDF_ENDPOINT = getDownloadPDFApiEndpoint(
        domainId,
        startAndEndDateTime
      );

      await downloadPdf(dispatch, DOWNLOAD_PDF_ENDPOINT);
    } else {
      handleErrorNotification(
        dispatch,
        CONSTANTS.DOWNLOAD_FOR_LESS_OR_EQUAL_TO_7_DAYS
      );
    }
    dispatch(setIsDownloadingPdf(false));
  };

  return (
    <Popover
      id={customDateId}
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        className: classes.popover,
      }}
    >
      <div
        id={`${customDateId}-container`}
        className={classes.datePickerContainer}
      >
        <MUIDateInput
          label="Start Date"
          id={'startDateInput'}
          value={startDate}
          onChange={(e: any) => {
            setStartDate(e?.target?.value);
            setEndDate(e?.target?.value);
          }}
        />

        <MUIDateInput
          label="End Date"
          id={'endDateInput'}
          value={endDate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEndDate(e?.target?.value)
          }
          required
          disabled={!startDate}
          variant="outlined"
          isEndDate={true}
          startDate={startDate}
        />
      </div>
      <Divider id="divider" className={classes.divider} />
      <div className={classes.actions}>
        <CustomButton
          backgroundColor="transparent"
          textColor="#909097"
          label={CONSTANTS.CANCEL_BTN_TEXT}
          onClick={() => setAnchorEl(null)}
          id={'pdfCancancelBtn'}
        />
        <CustomButton
          backgroundColor="transparent"
          textColor="#0046FF"
          id={'pdfOkBtn'}
          label={CONSTANTS.OK_BTN_TEXT}
          onClick={handleOkClick}
        />
      </div>
    </Popover>
  );
};

export default CustomDate;
