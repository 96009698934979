import React, { useState } from 'react';
import { Spinner } from '../../common/Spinner';
import Notification from '../../common/notification';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import './wrapper.css';
import { pushNotification } from '../../../redux/notification/notificationActions';
import Sidebar from '../sidebar/Sidebar';
import { Box } from '@mui/material';
import Header from '../header/Header';
import '../../../styles/EulaStyles.css';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 20%)',
    overflow: 'hidden'
  },
  mainContent: {
    backgroundColor: '#F2F2F2',
    height: 'calc(100vh - 3.8rem)',
    width: '95.5%',
    overflowY: 'auto',
    position: 'relative',
    left: '4.9%',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#0046FF',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#CCCCCC',
      backgroundClip: 'padding-box',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-button': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      left: '4.8%',
    },
    [theme.breakpoints.up('lg')]: {
      left: '4.5%',
      width: '95.5%',
    },
    [theme.breakpoints.up('xl')]: {
      left: '2.5%',
      width: '97.5%',
    },
    '@media (min-width:1533px) and (max-width:1919px)': {
      left: '3%',
      width: '98.5%',
    },
    '@media (min-width:2800px)': {
      left: '2%',
      width: '98.5%',
    },
  },
}));

const Wrapper = (props) => {
  const { showContent } = props;

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const dispatch = useDispatch();
  const { notification } = useSelector((state: any) => state.notification);

  const closeNotification = () => {
    dispatch(
      pushNotification({
        ...notification,
        isOpen: false,
      })
    );
  };

  const handleMouseEnter = () => {
    setIsSidebarVisible(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarVisible(false);
  };

  const classes = useStyles();

  return window?.location?.href?.indexOf('signup') <= -1 ? (
    <div id="wrapperContainer">
      {showContent ? (
        <div>
          <Header id="appHeader" />
          <div id="appContainer" className={classes.container}>
            <Sidebar
              id="appSidebar"
              open={isSidebarVisible}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
            <Box id={'appMainContent'} className={classes.mainContent}>
              {props?.children}
            </Box>
          </div>

          <Spinner />
          <Notification
            isOpen={notification?.isOpen}
            type={notification?.type}
            message={notification?.message}
            handleClose={closeNotification}
          />
        </div>
      ) : null}
    </div>
  ) : (
    <div>
      <Notification
        isOpen={notification?.isOpen}
        type={notification?.type}
        message={notification?.message}
        handleClose={closeNotification}
      />

      {props?.children}

      <Spinner />
    </div>
  );
};

export default Wrapper;
