/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { InputAdornment, Box } from '@mui/material';
import { ReactComponent as DomainLinkIcon } from '../../assets/icons/svg/carbon_link.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setDomain } from '../../redux/domainData/domainActions';

import CONSTANTS from '../../constants/constants';
import CustomSelect from '../../components/common/customSelect';
import DurationPicker from '../../components/common/customDate/DurationPicker';
import { makeStyles } from '@material-ui/core';
import { getSessionStorageItem, setSessionStorageItem } from '../../encrypt';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  durationPickerWrapper: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '72%',
    },
  },
}));

interface DomainControlProps {
  id: string;
  domain: any;
  setPage: any;
}

const DomainControl: React.FC<DomainControlProps> = ({ id, domain, setPage }) => {
  const [selectedDomain, setSelectedDomain] = useState<any>({});

  const { domainsByClient } = useSelector((state: any) => state?.domainData);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedItem = JSON.parse(
      getSessionStorageItem(CONSTANTS.DOMAIN_OBJ) ?? '{}'
    );
    if (storedItem?.domain_id) {
      setSelectedDomain(storedItem);
    } else {
      setSelectedDomain(domain);
    }
  }, [domain, domain?.domain_id]);

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDomainObj = event?.target?.value;
    setSelectedDomain(JSON.parse(selectedDomainObj));
    setSessionStorageItem(CONSTANTS.DOMAIN_OBJ, selectedDomainObj);
    dispatch(setDomain(JSON.parse(selectedDomainObj)));
    setPage(1);
  };

  const classes = useStyles();

  return (
    <Box data-testid="customSelectBox" id={id} className={classes.container}>
      <CustomSelect
        data-testid="custom-select"
        id={`${id}-textField`}
        options={domainsByClient?.map((eachDomain) => ({
          value: JSON.stringify(eachDomain),
          label: eachDomain?.domain_url,
        }))}
        value={JSON.stringify(selectedDomain)}
        onChange={handleDomainChange}
        startAdornment={
          <InputAdornment position="start">
            <DomainLinkIcon />
          </InputAdornment>
        }
      />
      <Box className={classes.durationPickerWrapper}>
        <DurationPicker setPage={setPage} />
      </Box>
    </Box>
  );
};

export default DomainControl;
