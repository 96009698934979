import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cyberwaspLogo from '../../../assets/icons/svg/logo.svg';
import { makeStyles } from '@material-ui/core';
import CONSTANTS from '../../../constants/constants';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40%',
    },
  },
  logo: {
    width: '10rem',
    height: 'auto',
    '@media (min-width:2560px)': {
      width: '12rem',
    },
    '@media (min-width:3840px)': {
      minHeight: '90px !important',
    },
  },
}));

interface LogoProps {
  id: string;
}

const Logo: React.FC<LogoProps> = ({ id }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      id={id}
      className={classes.logoContainer}
      onClick={() => navigate(CONSTANTS.NAVIGATE_TO_DASHBOARD)}
      data-testid="header-logo-testid"
    >
      <img
        id={`${id}-image`}
        src={cyberwaspLogo}
        alt="cybersophy logo"
        className={classes.logo}
      />
    </Box>
  );
};

export default Logo;
