import {
  ATTACKS_INFORMATION_TABLE,
  ATTACK_TRENDLINE_DATA,
  SET_COMBINED_RESPONSE,
  DURATION_DATA,
  MENUS_LIST,
  START_AND_END_DATE_TIME,
} from '../../constants/actions';
import { initialStatsData } from '../../components/initial/InitialStatsData';
import { calculateRange } from '../../screen/utils/convertTime';
import { durations } from '../../components/initial/initialButtons';

export const initialUiState = {
  menusList: [],
  startAndEndDateTime: {
    ...calculateRange('12h'),
  },
  durationData: durations?.[0]?.value,

  combinedResponse: {
    cardsData: [...initialStatsData],
    geoMapData: {
      legit: [],
      attack: [],
    },
    severityDetails: {
      totalCount: 0,
      attacksBySeverity: [
        { value: 0, name: 'High', percentage: 0 },
        { value: 0, name: 'Medium', percentage: 0 },
        { value: 0, name: 'Low', percentage: 0 },
      ],
    },
    requestStats: {
      responseCodes: [],
      owaspTop10Mapping: [],
      userAgents: [],
      uriHits: [],
      requestMethods: [],
    },
    attacksByCategoryData: [],
  },
  attacksTrendlineData: [],
  attackInformationData: {
    data: [],
    total: 0,
  }
};

export const loadingUiReducer = (state = initialUiState, action) => {
  switch (action.type) {
    case DURATION_DATA:
      return {
        ...state,
        durationData: action.payload,
      };

    case START_AND_END_DATE_TIME:
      return {
        ...state,
        startAndEndDateTime: action.payload,
      };

    case ATTACK_TRENDLINE_DATA:
      return {
        ...state,
        attacksTrendlineData: action.payload,
      };

    case ATTACKS_INFORMATION_TABLE:
      return {
        ...state,
        attackInformationData: action.payload,
      };
    case MENUS_LIST:
      return {
        ...state,
        menusList: action.payload,
      };
    case SET_COMBINED_RESPONSE:
      return {
        ...state,
        combinedResponse: action.payload,
      };

    default:
      return state;
  }
};
