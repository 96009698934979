/* eslint-disable prettier/prettier */
import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import DetailItems from '../../components/common/profileDetailsReusableComponent';

const useStyles = makeStyles(() => ({
  aboutDetails: {
    width: '50%',
  },
}));

interface AboutPageProps {
  id: string;
  data: {
    userName: string;
    department: string;
    emailId: string;
    mobileNumber: string;
  };
}

const AboutPage: React.FC<AboutPageProps> = ({ id, data }) => {
  const classes = useStyles();
  const aboutData = {
    'User name': data?.userName,
    'Job title': data?.department,
    'E-mail': data?.emailId,
    'Phone number': data?.mobileNumber,
    
  };

  return (
    <Box id={id} className={classes.aboutDetails}>
      <DetailItems data={aboutData} />
    </Box>
  );
};

export default AboutPage;
