import { MapChartProps } from '../dashboard/geoMapChart/type';

export const processData = (rawData) => {
  const methodsToShow = ['GET', 'POST', 'PUT'];

  const processedData = rawData?.filter(item => methodsToShow?.includes(item?.name));

  const others = rawData?.filter(item => !methodsToShow?.includes(item?.name));

  const othersValue = others.reduce((sum, item) => sum + item?.value, 0);

  if (othersValue > 0) {
      processedData.push({ 
          name: 'Others', 
          value: othersValue,
          breakdown: others  
      });
  }

  return processedData;
};



export const processHeatMapData = (data: MapChartProps['heatMapData']) => {
  const processedData: { [key: string]: number } = {};
  data?.forEach((item) => {
    processedData[item?.countryCode] = item?.count;
  });
  return processedData;
};