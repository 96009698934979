import React, { useState, useEffect } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import ReusableTable from '../../../components/common/customTable/ReusableTable';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import DeleteConfirmationDialog from '../../../components/common/customDeleteBox/DeleteConfirmationDialog';
import AutoCompleteClients from '../Client/AutoCompleteClients';
import {
  CustomFormLabel,
  StyledImg,
  StyledMuiIcon,
} from '../../../styles/reuseStyles';
import UserAddIcon from '../../../assets/icons/svg/user.svg';
import CONSTANTS from '../../../constants/constants';
import {
  deleteUserFromClient,
  getAllPaginatedClients,
  mapUserAndClient,
} from '../../../services/clientServices';
import {
  addOrEditOrDeleteUser,
  getAllUsersWithPagination,
} from '../../../services/userService';
import { handleErrorNotification, handleSuccessNotification } from '../../utils/notificationHelpers';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '38rem',
    padding: '2%',
  },
  modalHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalCloseIcon: {
    color: '#0046FF',
    cursor: 'pointer',
  },
  editIcon: {
    cursor: 'pointer',
    color: '#0046FF !important',
  },
  deleteIcon: {
    cursor: 'pointer',
    color:'#FB5607 !important'
  },
}));

export default function Users() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreateUser, setIsCreateUser] = useState(false);
  const [isEditUser, setIsEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserData, setSelectedUserData] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState<any>(null);
  const [userId, setUserId] = useState('');
  const [clientName, setClientName] = useState('');
  const [isAddClient, setIsAddClient] = useState(false);
  const [data, setData] = useState({});
  const [selectedUserClient, setSelectedUserClient] = useState('');

  const handleGetAllUsers = async (page, size, searchTerm) => {
    const response = await getAllUsersWithPagination(
      page + 1,
      size,
      searchTerm
    );

    setTotalRecords(response?.data?.totalElements);
    setRowData(
      response?.data?.content?.map((item) => {
        const userdata = item?.userData;
        return {
          ...userdata,
          id: item?.userId,
          maxHeight: 200,
        };
      })
    );
  };

  useEffect(() => {
    handleGetAllUsers(page, rowsPerPage, searchTerm);
  }, [page, rowsPerPage, searchTerm, isCreateUser, isEditUser]);

  useEffect(() => {
    handleGetAllPaginatedClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName, selectedUserClient, isEditUser]);

  const handleGetAllPaginatedClients = async () => {
    const res = await getAllPaginatedClients(
      1,
      10,
      'client_name',
      selectedUserClient
    );
    setClientsList(res?.data?.clients);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleSearch = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (userToDelete) {
      const res = await deleteUserFromClient(userToDelete?.id);
      if (res?.success) {
        handleSuccessNotification(dispatch, res?.message);
      } else {
        handleErrorNotification(dispatch, res?.message);

      }

      const response = await addOrEditOrDeleteUser(
        'delete',
        userToDelete?.id,
        null,
        userToDelete?.userName
      );
      if (response?.success) {
        handleSuccessNotification(dispatch, response?.message);
        handleGetAllUsers(page, rowsPerPage, searchTerm);
      } else {
        handleErrorNotification(dispatch, response?.message);
      }
      setOpenDeleteDialog(false);
      setUserToDelete(null);
    }
  };

  const handleEditUser = (row) => {
    setSelectedUser(row?.id);
    setSelectedUserClient(row?.clientName);
    setSelectedUserData(row);
    setIsEditUser(true);
  };

  const handleGetUserId = async (event, row) => {
    setIsAddClient(true);
    setSelectedUser(row?.id);
    setData(row);
    setUserId(row?.id);
  };

  const handleChangeClient = async (newValue, clientId) => {
    setClientName(newValue);

    const clientUserData = { client_id: clientId, user_id: userId };

    const res = await mapUserAndClient(clientUserData);

    if (res?.success) {
      handleSuccessNotification(dispatch, res?.message);
      handleGetAllUsers(page, rowsPerPage, searchTerm);
    } else {
      handleErrorNotification(dispatch, res?.error);
    }

    handleGetAllPaginatedClients();

    const newData: any = { ...data, clientName: newValue };

    setIsAddClient(false);
    await addOrEditOrDeleteUser('update', selectedUser, newData);

    handleGetAllUsers(page, rowsPerPage, searchTerm);
    setClientName('');
  };

  const handleClose = () => {
    setIsAddClient(false);
  };

  const columns = [
    { id: 'userName', label: 'User Name', minWidth: 100 },
    { id: 'firstName', label: 'First Name', minWidth: 100 },
    { id: 'lastName', label: 'Last Name', minWidth: 100 },
    { id: 'mobileNumber', label: 'Mobile No.', minWidth: 120 },
    { id: 'emailId', label: 'Email', minWidth: 150 },
    { id: 'clientName', label: 'Client Name', minWidth: 120 },
    { id: 'actions', label: 'Actions', minWidth: 100, align: 'center' },
  ];

  const StyledEditIcon = StyledMuiIcon(ModeEditOutlineOutlinedIcon);
  const StyledDeleteIcon = StyledMuiIcon(DeleteOutlineOutlinedIcon);

  const renderCustomCell = (column, row) => {
    if (column?.id === 'clientName') {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          {!row?.clientName || row?.clientName === '' ? (
            <IconButton data-testid="addClient" onClick={(event) => handleGetUserId(event, row)}>
              <StyledImg title="Add Client" src={UserAddIcon} />
            </IconButton>
          ) : (
            <Typography variant="h6">{row?.clientName}</Typography>
          )}
        </Box>
      );
    }
    if (column?.id === CONSTANTS.ACTIONS_TEXT) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <IconButton
            title="Edit User"
            onClick={() => handleEditUser(row)}
            className={classes.editIcon}
          >
            <StyledEditIcon />
          </IconButton>
          <IconButton
            title="Delete User"
            onClick={() => handleDeleteUser(row)}
            className={classes.deleteIcon}
          >
            <StyledDeleteIcon />
          </IconButton>
        </Box>
      );
    }
    return row[column?.id];
  };

  return (
    <>
      <ReusableTable
        id="usersTable"
        title="Users"
        columns={columns}
        data={rowData}
        totalCount={totalRecords}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onSearch={handleSearch}
        searchPlaceholder="Search users..."
        onAddItem={() => setIsCreateUser(true)}
        buttonLabel="Create New"
        renderCustomCell={renderCustomCell}
      />

      <CreateUser show={isCreateUser} setShow={setIsCreateUser} />

      <EditUser
        show={isEditUser}
        setShow={setIsEditUser}
        selectedUser={selectedUser}
        selectedUserClient={selectedUserClient}
        selectedUserData={selectedUserData}
        clientsList={clientsList}
        setSelectedUserClient={setSelectedUserClient}
        handleGetAllUsers={() =>
          handleGetAllUsers(page, rowsPerPage, searchTerm)
        }
      />
      <DeleteConfirmationDialog
        title={CONSTANTS.IS_DELETE_USER}
        description={CONSTANTS.DELETE_USER_CONFIRMATION_MSG}
        open={openDeleteDialog}
        onConfirm={confirmDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />

      <Modal open={isAddClient} onClose={() => setIsAddClient(false)}>
        <Box className={classes.modal}>
          <Box className={classes.modalHeader}>
            <CustomFormLabel color="blue">
              Add the Client to User
            </CustomFormLabel>
            <IconButton
              title={CONSTANTS.CLOSE_BTN_TEXT}
              className={classes.modalCloseIcon}
              onClick={handleClose}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <AutoCompleteClients
            id='mapClientToUserModal'
            handleChangeClient={handleChangeClient}
            initialClientName={clientName}
          />
        </Box>
      </Modal>
    </>
  );
}
