import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Box, IconButton } from '@mui/material';
import geoJsonData from '../../../components/initial/geo_map_topo_json.json';
import { formatNumber } from '../../utils/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './GeoMap.css';
import { makeStyles } from '@material-ui/core';
import { MapChartProps } from './type';
import { processHeatMapData } from '../../utils/processData';
import CONSTANTS, { MAP_COLORS } from '../../../constants/constants';
import { createCountryGroup, createCountryLabelGroup } from './mapUtils';

const ATTACK_FILTER = 'attack';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '90%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: '#E3F2FD',
    borderRadius: '0.5rem',
    '@media (min-width:2560px)': {
      height: '88%',
    },
    '@media (max-width:600px)': {
      height: '86%',
    },
    '@media (max-width:400px)': {
      height: '82%',
    },
  },
  svgContainer: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  svg: {
    position: 'absolute',
    top: '50%',
    left: '45%',
    transform: 'translate(-50%, -50%)',
    '@media (min-width:2560px)': {
      width: '90%',
      left: '40%',
      transform: 'translate(-40%, -50%)',
    },
  },
  zoomButtonContainer: {
    position: 'absolute',
    top: 16,
    right: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  zoomButton: {
    height: '1.8rem',
    width: '1.8rem',
    borderRadius: '0 !important',
    backgroundColor: '#FFFFFF !important',
    boxShadow: '0px 1.73px 4.34px 0px #0000001A',
    color: '#0D46C1 !important',
    '@media (min-width:2560px)': {
      height: '2.8rem',
      width: '2.8rem',
    },
    '@media (min-width:3840px)': {
      height: '3rem',
      width: '3rem',
    },
  },
  zoomInButton: {
    borderTopLeftRadius: '0.5rem !important',
    borderTopRightRadius: '0.5rem !important',
    borderBottom: '1px solid #D8D8D8 !important',
  },
  zoomOutButton: {
    borderBottomLeftRadius: '0.5rem !important',
    borderBottomRightRadius: '0.5rem !important',
  },
  tooltip: {
    position: 'absolute',
    textAlign: 'center',
    width: 'auto',
    height: 'auto',
    padding: '8px',
    fontSize: '12px',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    borderRadius: '4px',
    pointerEvents: 'none',
    zIndex: 10,
    opacity: 0,
    transition: 'opacity 0.2s',
  },
  customTooltip: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    background: '#fff',
    color: '#000',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    pointerEvents: 'none',
    zIndex: 10,
    opacity: 1,
    transition: 'opacity 0.2s',
  },
  tooltipArrow: {
    width: 0,
    height: 1,
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '5px solid #fff',
    position: 'absolute',
    bottom: '-2px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  tooltipFlag: {
    width: '24px',
    height: '18px',
    backgroundColor: '#E3F2FD',
    padding: 2,
  },
  tooltipTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  tooltipCountry: {
    color: '#475467',
    fontSize: '0.7rem',
  },
  tooltipCount: {
    fontSize: '0.9rem',
  },
  gradientContainer: {
    position: 'absolute',
    bottom: 18,
    right: 16,
    width: '2rem',
    height: '10rem',
    background: 'linear-gradient(to bottom, #FBDA61 2.88%, #FF4B55 98.13%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0',
  },
  gradientText: {
    color: '#000',
    fontSize: '0.8rem',
  },
  icon: {
    fontSize: '1rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.6rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '2rem !important',
    },
  },
});

const GeoMap: React.FC<MapChartProps> = ({ id, traffic, heatMapData }) => {
  const classes = useStyles();

  const STOP_COLOR =
    traffic === ATTACK_FILTER
      ? MAP_COLORS.ATTACK_STOP
      : MAP_COLORS.TRAFFIC_STOP;
  const START_COLOR =
    traffic === ATTACK_FILTER
      ? MAP_COLORS.ATTACK_START
      : MAP_COLORS.TRAFFIC_START;

  const gradientBackground =
    traffic === ATTACK_FILTER
      ? MAP_COLORS.ATTACK_GRADIENT
      : MAP_COLORS.TRAFFIC_GRADIENT;

  const mapContainerRef = useRef<any>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [isZoomingIn, setIsZoomingIn] = useState(false);
  const [isZoomingOut, setIsZoomingOut] = useState(false);
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown>>();

  const counts = heatMapData?.map((item) => item?.count);
  const minCount = Math.min(...counts);
  const maxCount = Math.max(...counts);

  const handleZoom = useCallback((direction: 'in' | 'out') => {
    if (!zoomRef?.current) return;

    const svg = d3?.select(mapContainerRef?.current);
    const currentTransform = d3?.zoomTransform(svg?.node() as Element);

    const newScale =
      direction === 'in'
        ? currentTransform?.k * 1.05
        : currentTransform?.k / 1.05;

    zoomRef?.current?.scaleTo(svg, newScale);
  }, []);

  useEffect(() => {
    let animationFrameId: number;

    const animate = () => {
      if (isZoomingIn) {
        handleZoom('in');
      } else if (isZoomingOut) {
        handleZoom('out');
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    if (isZoomingIn || isZoomingOut) {
      animate();
    }

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [handleZoom, isZoomingIn, isZoomingOut]);

  useEffect(() => {
    const svg = d3.select(mapContainerRef?.current);
    const processedHeatMapData = processHeatMapData(heatMapData);

    const handleResize = () => {
      const container = svg?.node()?.parentNode;
      const containerWidth = container?.clientWidth;
      const containerHeight = container?.clientHeight;

      const originalWidth = 800;
      const originalHeight = 500;

      const scaleX = containerWidth / originalWidth;
      const scaleY = containerHeight / originalHeight;
      const scale = Math.min(scaleX, scaleY) * 1.25;

      const newWidth = originalWidth * scale;
      const newHeight = originalHeight * scale;

      const projection = d3
        .geoNaturalEarth1()
        .fitSize([newWidth, newHeight], geoJsonData);
      const path = d3.geoPath().projection(projection);

      svg.attr('width', newWidth).attr('height', newHeight);

      projection.fitSize([newWidth, newHeight], geoJsonData);
      svg.selectAll('g').remove();

      const countryGroup = createCountryGroup(
        svg,
        path,
        processedHeatMapData,
        START_COLOR,
        STOP_COLOR
      );

      const labelGroup = createCountryLabelGroup(svg, path, heatMapData);

      zoomRef.current = d3.zoom().on('zoom', (event) => {
        if (
          event.sourceEvent &&
          (event.sourceEvent.type === CONSTANTS.WHEEL_TEXT ||
            event.sourceEvent.type === CONSTANTS.DOUBLE_CLICK_TEXT)
        ) {
          return;
        }
        countryGroup.attr('transform', event.transform);
        labelGroup.attr('transform', event.transform);
      });

      svg
        .call(zoomRef.current)
        .on('wheel.zoom', null)
        .on('dblclick.zoom', null);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [heatMapData, id, STOP_COLOR, START_COLOR, traffic]);

  const renderZoomControls = () => (
    <Box className={classes.zoomButtonContainer}>
      <IconButton
        onMouseDown={() => setIsZoomingIn(true)}
        onMouseUp={() => setIsZoomingIn(false)}
        onMouseLeave={() => setIsZoomingIn(false)}
        className={`${classes.zoomButton} ${classes.zoomInButton}`}
      >
        <AddIcon className={classes.icon} />
      </IconButton>
      <IconButton
        onMouseDown={() => setIsZoomingOut(true)}
        onMouseUp={() => setIsZoomingOut(false)}
        onMouseLeave={() => setIsZoomingOut(false)}
        className={`${classes.zoomButton} ${classes.zoomOutButton}`}
      >
        <RemoveIcon className={classes.icon} />
      </IconButton>
    </Box>
  );

  const renderSvgShadow = () => (
    <defs>
      <filter
        id="glow"
        filterUnits="objectBoundingBox"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur stdDeviation="20" result="coloredBlur" />
        <feFlood floodColor={MAP_COLORS.GLOW} result="glowColor" />
        <feComposite
          in="glowColor"
          in2="coloredBlur"
          operator="in"
          result="coloredGlow"
        />
        <feMerge>
          <feMergeNode in="coloredGlow" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  );

  const renderGradientBar = () => (
    <div
      id={`${id}-gradientBarContainer`}
      data-testid="geoMapGradientBar"
      className="gradient-bar"
    >
      <div
        id={`${id}-gradientBar`}
        style={{ background: gradientBackground }}
        className="gradient-rectangle"
      >
        <div id={`${id}-gradientBarMinValue`} className="min-label">
          {formatNumber(minCount)}
        </div>
        <div
          id={`${id}-gradientBarMaxValue`}
          className={
            maxCount >= 100 ? 'max-label' : 'max-label max_label-single-digit'
          }
        >
          {formatNumber(maxCount)}
        </div>
      </div>
    </div>
  );

  return (
    <Box id={id} className={classes.root}>
      <Box component="div" className={classes.svgContainer}>
        <svg
          ref={mapContainerRef}
          id={`svgContainer-${id}`}
          className={classes.svg}
          filter="url(#glow)"
        >
          {renderSvgShadow()}
        </svg>
        <div ref={tooltipRef} className={classes.tooltip} />
      </Box>

      {renderZoomControls()}
      {renderGradientBar()}
    </Box>
  );
};

export default GeoMap;
