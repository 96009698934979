import CONSTANTS from '../constants/constants';
import { MENUS } from '../constants/endpoints';
import { getSessionStorageItem, setSessionStorageItem } from '../encrypt';
import { setIsWafAdmin } from '../redux/authentication/authActions';
import { setMenusList } from '../redux/ui/uiActions';
import { request } from './request';

const CLIENTS_PATH = '/clients';

export const getMenusApi = async (dispatch) => {
  try {
    const response: any = await request.get(
      `${getSessionStorageItem(CONSTANTS.AWGMENT_API)}${MENUS}`
    );
    if (response?.success) {
      const isAllowed =
        response?.data?.findIndex((menu) => menu?.url === CLIENTS_PATH) !== -1;

      dispatch(setIsWafAdmin(isAllowed));

      setSessionStorageItem(CONSTANTS.IS_ADMIN_USER, `${isAllowed}`);
      dispatch(setMenusList(response?.data));
    } else {
      dispatch(setIsWafAdmin(false));
      dispatch(setMenusList([]));
      return false;
    }
  } catch (error) {
    return false;
  }
};
