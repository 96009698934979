/* eslint-disable prettier/prettier */
import React from 'react';
import { TextField, MenuItem, TextFieldProps } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as DownArrow } from '../../../assets/icons/svg/downArrow.svg';

const breakpointStyles = {
  xs: '0.9rem',
  sm: '0.95rem',
  md: '0.9rem',
  lg: '1rem',
  xl: '1.2rem',
  '2560px': '1.5rem',
  '3840px': '1.8rem',
};

const useStyles = makeStyles((theme) => ({
  selectField: {
    '& .MuiInputBase-root': {
      fontSize: '0.9rem',
      color: (props: { height; color?: string }) => props?.color ?? '#6E7079',
      height: (props: { height?: string }) => props?.height ?? 45,
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.up('xs')]: {
        fontSize: breakpointStyles.xs,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: breakpointStyles.sm,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: breakpointStyles.md,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: breakpointStyles.lg,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: breakpointStyles.xl,
      },
      '@media (min-width:2560px)': {
        fontSize: breakpointStyles['2560px'],
        height: 50,
      },
      '@media (min-width:3840px)': {
        fontSize: breakpointStyles['3840px'],
        height: 55,
      },
    },
  },

  textField: {
    borderRadius: '0.5rem !important',
    border: '1px solid #0046FF !important',
  },
  selectIcon: {
    right: 12,
    margin: -4,
  },
  menuItem: {
    fontSize: '1rem !important',
    color: '#696969 !important',
    [theme.breakpoints.up('xs')]: {
      fontSize: breakpointStyles.xs,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: breakpointStyles.sm,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: breakpointStyles.md,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: breakpointStyles.lg,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: breakpointStyles.xl,
    },
    '@media (min-width:2560px)': {
      fontSize: breakpointStyles['2560px'],
    },
    '@media (min-width:3840px)': {
      fontSize: breakpointStyles['3840px'],
    },
  },
  activeMenuItem: {
    backgroundColor: '#0046FF !important',
    color: '#FFFFFF !important',
  },
}));

interface CustomSelectProps extends Omit<TextFieldProps, 'variant'> {
  id: string;
  options: Array<{ value: string; label: string }>;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  additionalProps?: any;
  height?: any;
  width?: string;
  label?: string;
  color?: any;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  options,
  value,
  onChange,
  startAdornment,
  endAdornment,
  label,
  height,
  color,
  variant = 'outlined',
  size = 'medium',
  fullWidth = true,
  additionalProps = {},
  width = '100%',
}) => {
  const classes = useStyles({ height, color });

  return (
    <TextField
      id={id}
      select
      size={size}
      value={value}
      onChange={onChange}
      variant={variant}
      fullWidth={fullWidth}
      SelectProps={{
        IconComponent: DownArrow,
        classes: { icon: classes.selectIcon },
      }}
      className={classes.selectField}
      InputProps={{
        classes: { notchedOutline: classes.textField },
        startAdornment: startAdornment,
        endAdornment: endAdornment,
      }}
      sx={{ width }}
      {...additionalProps}
    >
      {options?.map((option) => {
        return (
          <MenuItem
            classes={{
              root: classes.menuItem,
              selected: classes.activeMenuItem,
            }}
            className={classes.menuItem}
            id={`${id}-menuItem`}
            key={option?.value}
            value={option?.value}
          >
            {option?.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default CustomSelect;
