import { DOMAIN_OBJECT, DOMAIN_URLS_BY_CLIENT } from '../../constants/actions';

const initialDomainState = {
  domainsByClient: [],
  domain: {},
};

export const domainReducer = (state = initialDomainState, action) => {
  switch (action.type) {
    case DOMAIN_URLS_BY_CLIENT:
      return {
        ...state,
        domainsByClient: action.payload,
      };

    case DOMAIN_OBJECT:
      return {
        ...state,
        domain: action.payload,
      };

    default:
      return state;
  }
};
