import {
  EULA_TEXT_DOWNLOAD_ENDPOINT,
  USER_PREFERENCE,
  PROFILE_PICTURE_ENDPOINT,
} from '../constants/endpoints';
import { ResponseProps, request } from './request';
import CONSTANTS from '../constants/constants';
import { getSessionStorageItem } from '../encrypt';
import { REQUEST_METHODS } from '../constants/common';


export const fetchFileText = async () => {
  const Token = getSessionStorageItem(CONSTANTS.REACT_TOKEN);

  const fetchEulaTextUrl = `${getSessionStorageItem(
    CONSTANTS.AWGMENT_API
  )}${EULA_TEXT_DOWNLOAD_ENDPOINT}${getSessionStorageItem(
    CONSTANTS.EULA_DOC_ID
  )}`;

  try {
    const response = await fetch(fetchEulaTextUrl, {
      method: REQUEST_METHODS.GET,
      headers: new Headers({
        Authorization: `Bearer ${Token}`,
      }),
    });
    const blobData = await response.blob();
    return blobData.text();
  } catch (error) {
    throw new Error(CONSTANTS.FAILED_TO_FETCH_EULA_MSG);
  }
};

export const uploadProfileImage = async (
  file: string,
  name: string
): Promise<{ success: boolean; message?: string; data?: any }> => {
  const blob = new Blob([file]);
  const fileOfBlob = new File([blob], `${name}`);
  const params = {
    file: fileOfBlob,
  };
  const r: ResponseProps = (await request.postForm(
    `${getSessionStorageItem(
      CONSTANTS.AWGMENT_API
    )}${USER_PREFERENCE}${PROFILE_PICTURE_ENDPOINT}`,
    params
  )) as ResponseProps;
  if (r) {
    return {
      success: r.success ? true : false,
      message: r.message,
      data: r.data,
    };
  }
  return { success: false };
};
