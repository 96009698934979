import { DOMAIN_URLS_BY_CLIENT, DOMAIN_OBJECT } from '../../constants/actions';

export const setDomainsByClient = (domainsByClient) => {
  return {
    type: DOMAIN_URLS_BY_CLIENT,
    payload: domainsByClient,
  };
};

export const setDomain = (domainObj) => {
  return {
    type: DOMAIN_OBJECT,
    payload: domainObj,
  };
};
