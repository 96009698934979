/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingTop: 10,
    color: '#0046FF !important',
  },
  logOutText: {
    color: '#0046FF !important',
    paddingTop: 5,
  },
}));

const Logout: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div data-testid="logout-loader" className="eula_loader"></div>
      <Typography
        data-testid="logoutText"
        className={classes.logOutText}
        variant="h3"
      >
        Logging out...
      </Typography>
    </Box>
  );
};

export default Logout;
