import { USER_PROFILE_DATA, USER_PREFERENCES } from '../../constants/actions';

export const setUserProfileData = (userProfileData) => {
  return {
    type: USER_PROFILE_DATA,
    payload: userProfileData,
  };
};

export const setUserPreferences = (userPreferences) => {
  return {
    type: USER_PREFERENCES,
    payload: userPreferences,
  };
};
