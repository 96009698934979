/* eslint-disable prettier/prettier */
import { format } from 'date-fns';
import { getDaysDifference } from './convertTime';
import { DURATIONS } from '../../constants/constants';



const customFormat = (diffDays) => {
  let formatString: string;
  switch (true) {
    case diffDays < 2:
      formatString = 'dd/MM hha';
      break;
    case diffDays >= 2 && diffDays <= 10:
      formatString = 'dd/MM';
      break;
    case diffDays >= 11 && diffDays <= 32:
      formatString = 'dd/MMM';
      break;
    case diffDays >= 32 && diffDays <= 180:
      formatString = 'dd/MMM';
      break;
    default:
      formatString = 'dd/MMM/yyyy';
      break;
  }
  return formatString
}
export const formatXAxis = (
  tickItem: any,
  index: number,
  label: string,
  startAndEndDate: { from: Date; to: Date }
) => {
  const date = new Date(tickItem);
  const start = startAndEndDate?.from;
  const end = startAndEndDate?.to;

  const diffDays = getDaysDifference(start, end);

  switch (label) {
    case DURATIONS.HOURS_12:
    case DURATIONS.HOURS_24:
      return format(date, 'hh a');
    case DURATIONS.WEEK:
      return format(date, 'dd/MM');
    case DURATIONS.MONTH:
      return format(date, 'MMM/dd');
    case DURATIONS.YEAR:
      return format(date, 'dd/MMM/yyyy');
    default:
      return format(date, customFormat(diffDays));
  }
};
