/* eslint-disable prettier/prettier */
import { createTheme } from '@mui/material';
import SIZE from './font_size.json';

const primary = '#1FA7D3';
const primaryLight = '#1FA7D3';
const secondary = '#fff';
const error = '#f17b7b';

export const INITIAL_THEME = createTheme({
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
    },
    secondary: {
      main: secondary,
    },
    error: {
      main: error,
    },
  },
  typography: {
    fontFamily: '\'Aeonik\', sans-serif',
    h1: {
      fontSize: '1.2rem',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.8rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '2rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '2.2rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '2.5rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '2.8rem',
      },
    },
    h2: {
      letterSpacing: 0,
      fontSize: '0.9rem',
      '@media (min-width:600px)': {
        fontSize: '1rem',
      },
      '@media (min-width:960px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1.12rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.15rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.95rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '2rem',
      },
    },
    h3: {
      color: '#6E7079',
      fontSize: '0.8rem',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.95rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.15rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.2rem',
      },
    },
    h4: {
      fontSize: '0.7rem',
      color: '#6E7079',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.75rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.5rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.6rem',
      },
    },
    h5: {
      fontSize: '0.6rem',
      fontWeight: 'bold',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.7rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.05rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.10rem',
      },
    },
    h6: {
      fontSize: '0.5rem',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.6rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem',
      },
    },
    subtitle1: {
      fontSize: SIZE[4],
      fontWeight: 'normal',
      color: primary,
    },
    subtitle2: {
      fontSize: SIZE[3],
      fontWeight: 'normal',
      color: primary,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem',
      },
    },
    body2: {
      fontSize: '0.9rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      '@media (min-width:600px)': {
        fontSize: '0.8rem',
      },
      '@media (min-width:960px)': {
        fontSize: '0.9rem',
      },
      '@media (min-width:1280px)': {
        fontSize: '1rem',
      },
      '@media (min-width:1920px)': {
        fontSize: '1.1rem',
      },
      '@media (min-width:2560px)': {
        fontSize: '1.4rem',
      },
      '@media (min-width:3840px)': {
        fontSize: '1.8rem',
      },
    },
    button: {
      fontFamily: 'Aeonik',
      fontWeight: 600,
      textTransform: 'none',
    },
    caption: {
      fontSize: SIZE[0],
      fontWeight: 'normal',
      color: primary,
    },
    overline: {
      fontSize: SIZE[0],
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  },
});
