/* eslint-disable prettier/prettier */
import React from 'react';
import * as Yup from 'yup';
import { addOrEditOrDeleteClient } from '../../../services/clientServices';
import { useDispatch } from 'react-redux';
import ReusableForm from '../../../components/common/customForm';
import { handleErrorNotification, handleSuccessNotification } from '../../utils/notificationHelpers';

const validationSchema = Yup.object({
  domain_name: Yup.string(),
  domain_url: Yup.string(),
  ip_address: Yup.string(),
  current_mode: Yup.string(),
  hosting_service_name: Yup.string(),
  dns_mapped: Yup.boolean(),
  mapped_on: Yup.string(),
});

interface EditDomainProps {
  clientId: string;
  handleGetPaginatedClients: any;
  isEditing: boolean;
  onClose: () => void;
  domainInfo: any;
}

const editDomainFields = [
  { name: 'domain_name', label: 'Domain Name', type: 'text', placeholder: 'Enter domain name',required: true }, 
  { name: 'domain_url', label: 'Domain URL', type: 'text', placeholder: 'Enter domain URL',required: true, disabled: true },
  { name: 'ip_address', label: 'IP Address', type: 'text', placeholder: 'Enter IP address' },
  { name: 'current_mode', label: 'Current Mode', type: 'text', placeholder: 'Transparent Mode',disabled: true },
  { name: 'hosting_service_name', label: 'Hosting Service Name', type: 'text', placeholder: 'Enter hosting service' },
  { name: 'dns_mapped', label: 'DNS Mapped', type: 'checkbox' },
  { name: 'mapped_on', label: 'Mapped On', type: 'text', placeholder: 'Mapped on date' ,disabled: true},
];



const EditDomain: React.FC<EditDomainProps> = ({
  clientId,
  handleGetPaginatedClients,
  isEditing,
  onClose,
  domainInfo,
}) => {
  const initialValues = {
    domain_name: domainInfo?.domain_name,
    domain_url: domainInfo?.domain_url,
    ip_address: domainInfo?.ip_address,
    current_mode: domainInfo?.current_mode,
    hosting_service_name: domainInfo?.hosting_service_name,
    dns_mapped: domainInfo?.dns_mapped,
    mapped_on: domainInfo?.mapped_on,
  };
  

  const dispatch = useDispatch();

  const handleEditDomainApi = async (postData) => {
    const response: any = await addOrEditOrDeleteClient(
      'edit-domain',
      clientId,
      postData,
      domainInfo?.domain_id
    );
    if (response?.success) {
      if (handleGetPaginatedClients) {
        handleGetPaginatedClients();
      }

      handleSuccessNotification(dispatch,response?.message)
      onClose();
    } else {
      handleErrorNotification(dispatch,response?.detail?.message)
    }
  };
  const handleSubmit = (values) => {
    const editedDomainData = {
      current_mode: values?.current_mode,
      domain_name: values?.domain_name,
      dns_mapped: values?.dns_mapped,
      domain_url: values?.domain_url,
      hosting_service_name: values?.hosting_service_name,
      ip_address: values?.ip_address,
    };

    handleEditDomainApi(editedDomainData);
  };

  return (
    <ReusableForm 
      id={'editDomainForm'} 
      open={isEditing} 
      onClose={onClose} 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={handleSubmit} 
      fields={editDomainFields} 
      title={'Edit Domain'} 
      buttonLabel={'Edit'}/>
  )
};

export default EditDomain;
