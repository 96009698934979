import { PUSH_NOTIFICATION } from '../../constants/actions';

export interface NotificationProps {
  isOpen: boolean;
  type?: 'error' | 'info' | 'success' | 'warning';
  message?: string;
  handleClose?: () => void;
}
const initialNotificationState = {
  notification: {},
};

export const notificationReducer = (
  state = initialNotificationState,
  action
) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return { ...state, notification: action.payload };

    default:
      return state;
  }
};
