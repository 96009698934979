/* eslint-disable prettier/prettier */
import React from 'react';
import ReusableTable from '../../components/common/customTable/ReusableTable';

interface DomainsPageProps {
  id: string;
  data: any[];
}

const DomainsPage: React.FC<DomainsPageProps> = ({ id, data }) => {
  const columns = [
    { id: 'domain_name', label: 'Domain name', minWidth: 100 },
    { id: 'domain_url', label: 'Domain URL', minWidth: 100 },
    { id: 'current_operational_mode', label: 'Operational mode', minWidth: 120 },
  ];

  return (
    
    <ReusableTable 
        id={id} 
        columns={columns} data={data} 
        showSearch={false}
        showTitle={false}
        showAddButton={false}
        showPagination={false}
        headerBgColor='#fff'
        headerTextColor='#0046FF'
        disablePadding={true} />
        
  )
};

export default DomainsPage;
