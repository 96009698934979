/* eslint-disable prettier/prettier */
import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { PlaceholderComponent } from './PlaceHolderComponent';

import '../../styles/DashboardStyles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    height: '90%',
    display: 'flex',
    alignItems: 'center',
  },
  contentBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0.5),
    paddingTop: 0,
    flex: 1,
  },
  title: {
    fontWeight: 400,
    color: '#3D4655 !important',
  },
  value: {
    fontSize: '1.7rem !important',
    color: '#3D4655',
    '@media (max-width:1025px)': {
      fontSize: '1.5rem !important',
    },
    '@media (min-width:2560px)': {
      fontSize: '2.5rem !important',
    },
  },
  growthBox: {
    display: 'flex',
    alignItems: 'center',
  },
  growthColor: {
    color: '#43C702',
  },
  fallColor: {
    color: '#FD1515',
  },

  growthRateDescription: {
    fontSize: '0.85rem !important',
    marginRight: '0.2rem !important',
    marginLeft: '0.2rem !important',
  },
  description: {
    color: '#3D4655',
    fontSize: '0.75rem !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '0.85rem !important',
    },
  },
  gradientBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
  },
}));

const StatCard = ({
  title,
  value,
  icon: Icon,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.contentBox}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography fontWeight={600} className={classes.value} variant="h2">
          {loading ? <div className="dots_loader"></div> : value}
        </Typography>
      </Box>
      <Box className={classes.gradientBox}>
        <Icon />
      </Box>
    </Box>
  );
};

interface StatsCardsProps {
  id: string;
  statsData: any;
}

const StatsCards: React.FC<StatsCardsProps> = ({ id, statsData }) => {
  return (
    <Grid id={id} data-testid={'test-stats'} container spacing={2}>
      {statsData
        ?.filter((stat) => stat?.id !== 'attack_percentage')
        ?.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={`${stat?.id}`}>
            <PlaceholderComponent
              id={`${id}-placeHolder`}
              text={''}
              marginBottom={0}
              padding={0}
              height={{ xs: 100, md: 120, xl: 140 }}
            >
              <StatCard
                title={stat?.title}
                value={stat?.value}
                icon={stat?.icon}
                loading={stat?.isLoading}
              />
            </PlaceholderComponent>
          </Grid>
        ))}
    </Grid>
  );
};

export default StatsCards;
