import { IS_WAF_ADMIN } from '../../constants/actions';

const initialAuthState = {
  isWafAdmin: false,
};

export const authActionReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case IS_WAF_ADMIN:
      return {
        ...state,
        isWafAdmin: action.payload,
      };

    default:
      return state;
  }
};
