import {
  REFRESH_PAGE,
  ATTACKS_INFORMATION_LOADER,
  ATTACK_TRENDLINE_LOADER,
  DOWNLOAD_PDF,
  SET_COMBINED_LOADER,
} from '../../constants/actions';


export const setIsRefresh = () => {
  return {
    type: REFRESH_PAGE,
  };
};

export const setAttackTrendlineLoader = (loading) => ({
  type: ATTACK_TRENDLINE_LOADER,
  payload: loading,
});

export const setAttacksInformationLoader = (loading) => ({
  type: ATTACKS_INFORMATION_LOADER,
  payload: loading,
});

export const setIsDownloadingPdf = (isDownloadingPdf) => ({
  type: DOWNLOAD_PDF,
  payload: isDownloadingPdf,
});

export const setCombinedResponseLoader = (combinedLoader) => ({
  type: SET_COMBINED_LOADER,
  payload: combinedLoader,
});
