/* eslint-disable prettier/prettier */
import { Box } from '@mui/material';

import PageNotFound from '../assets/icons/svg/pageNotFound.svg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container:{
    width: '100%',
    marginTop: '5% !important',
    borderRadius: '1% !important',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
}));

const NoPageFound: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <div className={classes.imgContainer}>
        <img src={PageNotFound} alt="page not found" />
      </div>
    </Box>
  );
};

export default NoPageFound;
