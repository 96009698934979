// awgment api endpoints

export const USER_PROCESS_DEFINITION_KEY = 'Process_844844770998710300';

export const USERS_ENDPOINT = '/accounts/v1/users';
export const USER_PREFERENCE = '/accounts/v1/users/preferences';
export const MENUS = '/accounts/v1/roles/menu';

export const START_PROCESS = '/workflow/v1/process/start';

// EULA endpoints

export const EULA_CHECK_ENDPOINT = '/dashboard/check-eula';
export const EULA_SET_ENDPOINT = '/client_user/map-client-user/';
export const EULA_TEXT_DOWNLOAD_ENDPOINT = '/dms/v1/documents/download?id=';

// User Profile
export const USER_PROFILE_ENDPOINT = '/dashboard/user-profile/';

export const DOMAINS_BY_CLIENT_ENDPOINT = '/dashboard/clients/domains';

export const PROFILE_PICTURE_ENDPOINT = '/profile-picture'

export const getAttacksTrendlineEndpoint = (
  domainId,
  startAndEndDateTime,
  timeline
) =>`/dashboard/trendline/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}&timeline=${timeline}`;

export const getViewDetailsEndpoint = (
  domainId,
  uniqueId,
  startAndEndDateTime
) =>`/dashboard/view-attack/${domainId}?unique_id=${uniqueId}&start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`;

export const getDownloadPDFApiEndpoint = (domainId, startAndEndDateTime) =>`/dashboard/download-pdf/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}`;

export const getAttacksInformationEndpoint = (
  domainId,
  startAndEndDateTime,
  page,
  searchTerm
) =>`/dashboard/attacks-info/${domainId}?start_date=${startAndEndDateTime.from}&end_date=${startAndEndDateTime.to}&page=${page}&search=${searchTerm}`;


export const NO_DOMAINS_ONBOARDED_ENDPOINT = 'no-domains-onboarded';

// client screen api endpoints

export const getClientsDomainsListEndpoint = (page, rowsPerPage, search) =>`/client_user/clients-domains-list?page=${page}&limit=${rowsPerPage}${search}`;
export const ADD_CLIENT_API_ENDPOINT = '/client_user/add-client';
export const getClientUpdationEndpoint = (clientId) =>`/client_user/client/${clientId}`;
export const getDeleteClientEndpoint = (clientId) =>`/client_user/client/${clientId}`;
export const CLIENT_USER_MAPPING_ENDPOINT = '/client_user/map-client-user';
export const DELETE_CLIENT_USER_MAPPING_API_ENDPOINT ='/client_user/map-client-user';
export const getDeleteDomainEndpoint = (domainId) =>`/client_user/domain/${domainId}`;
export const ADD_CLIENT_DOMAIN_API_ENDPOINT = '/client_user/add-domain';
export const getEditDomainEndpoint = (domainId) =>`/client_user/domain/${domainId}`;

export const getCombinedResponseEndpoint = (domainId, startAndEndDateTime) =>`/dashboard/main/${domainId}?start_date=${startAndEndDateTime?.from}&end_date=${startAndEndDateTime?.to}`;

// core rule set api endpoints

export const GET_CRS_RULES_ENDPOINT = '/client_user/rules/all-rules';
export const ADD_CRS_RULE_ENDPOINT = '/client_user/rules/add-rule';
export const UPDATE_CRS_RULE_ENDPOINT = '/client_user/rules/update-rule';
export const DELETE_CRS_RULE_ENDPOINT = '/client_user/rules/delete-rule';

export const UPLOAD_CRS_RULES_ENDPOINT = '/client_user/rules/upload-crs';
