/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18nextInit';
import CONSTANTS from './constants/constants';
import { getSessionStorageItem } from './encrypt';

if (getSessionStorageItem(CONSTANTS.REACT_TOKEN)) {
  let appConfig = {
    realm:
      getSessionStorageItem(CONSTANTS.KC_REALM_NAME) ||
      process.env.REACT_APP_REALM,
    authURL:
      getSessionStorageItem(CONSTANTS.KC_AUTH_URL) ||
      process.env.REACT_APP_AUTH_URL,
    clientId:
      getSessionStorageItem(CONSTANTS.KC_CLIENT_ID) ||
      process.env.REACT_APP_CLIENTID,
    gatewayURL:
      getSessionStorageItem(CONSTANTS.GATEWAY_URL) ||
      process.env.REACT_APP_GATEWAY_URL,
    awgmentApi:
      getSessionStorageItem(CONSTANTS.AWGMENT_API) ||
      process.env.REACT_APP_AWGMENT_URL,
    eulaDocId:
      getSessionStorageItem(CONSTANTS.EULA_DOC_ID) ||
      process.env.REACT_APP_EULA_DOC_ID,
    dmsDocId: getSessionStorageItem(CONSTANTS.DMS_DOC_ID),
  };

  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App config={appConfig} />
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  fetch(`${window.location.origin}${window.location.pathname}.json`)
    .then(async (response) => {
      return response.json();
    })
    .then((config) => {
      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(
        <React.StrictMode>
          <Router>
            <App config={config} />
          </Router>
        </React.StrictMode>,
        document.getElementById('root')
      );
    })
    .catch((error) => {
      let config = {
        realm: process.env.REACT_APP_REALM,
        authURL: process.env.REACT_APP_AUTH_URL,
        clientId: process.env.REACT_APP_CLIENTID,
        gatewayURL: process.env.REACT_APP_GATEWAY_URL,
        awgmentApi: process.env.REACT_APP_AWGMENT_URL,
        eulaDocId: process.env.REACT_APP_EULA_DOC_ID,
        dmsDocId: '',
      };

      // eslint-disable-next-line react/no-deprecated
      ReactDOM.render(
        <React.StrictMode>
          <Router>
            <App config={config} />
          </Router>
        </React.StrictMode>,
        document.getElementById('root')
      );
    });
}
