import CONSTANTS from '../constants/constants';
import { USER_PROFILE_ENDPOINT } from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import {
  setUserPreferences,
  setUserProfileData,
} from '../redux/profile/profileActions';
import { request } from './request';
import { getUserPreferences } from './userService';

export const userProfilePageApi = async (dispatch) => {
  try {
    const response: any = await request.get(
      `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${USER_PROFILE_ENDPOINT}${getSessionStorageItem(
        CONSTANTS.AWGMENT_USER_ID
      )}`
    );

    dispatch(setUserProfileData(response));
  } catch (error) {
    dispatch(setUserProfileData({}))
    ;}
};

export const getUserProfileImage = async (dispatch) => {
  const response = await getUserPreferences(dispatch, setUserPreferences);
  dispatch(setUserPreferences(response?.data));
};
