export const getMonthDetails = (month) => {
  if (!month) return null;
  const daysInMonth = new Date(
    month?.getFullYear(),
    month?.getMonth() + 1,
    0
  ).getDate();
  const firstDayOfMonth = new Date(
    month?.getFullYear(),
    month?.getMonth(),
    1
  ).getDay();

  const prevMonthDays = new Date(
    month?.getFullYear(),
    month?.getMonth(),
    0
  ).getDate();

  return { daysInMonth, firstDayOfMonth, prevMonthDays };
};
