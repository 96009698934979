import {
  REFRESH_PAGE,
  GEO_LOCATION_LOADER,
  ATTACK_TRENDLINE_LOADER,
  ATTACKS_INFORMATION_LOADER,
  DOWNLOAD_PDF,
  SET_COMBINED_LOADER,
} from '../../constants/actions';

const initialLoadingState = {
  isRefresh: false,
  geoLocationLoading: false,
  attackTrendlineLoading: false,
  attacksInformationLoading: false,
  isDownloadingPdf: false,
  combinedLoader: false,
};

export const loadingActionReducer = (state = initialLoadingState, action) => {
  switch (action.type) {
    case REFRESH_PAGE:
      return {
        ...state,
        isRefresh: !state.isRefresh, 
      };
   
    case GEO_LOCATION_LOADER:
      return { ...state, geoLocationLoading: action.payload };
    case ATTACK_TRENDLINE_LOADER:
      return { ...state, attackTrendlineLoading: action.payload };
    case ATTACKS_INFORMATION_LOADER:
      return { ...state, attacksInformationLoading: action.payload };
    case DOWNLOAD_PDF:
      return { ...state, isDownloadingPdf: action.payload };
    case SET_COMBINED_LOADER:
      return { ...state, combinedLoader: action.payload };

    default:
      return state;
  }
};
