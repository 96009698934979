import React from 'react';
import { GlobalStyles } from '@mui/system';

const GlobalStylesComponent = () => (
  <GlobalStyles
    styles={{
      fontFamily:'Aeonik',
      '::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#0046FF',
        borderRadius: '8px',
      },
      '::-webkit-scrollbar-track': {
        background: '#CCCCCC',
        backgroundClip: 'padding-box',
        borderRadius: '8px',
      },
      '::-webkit-scrollbar-button': {
        display: 'none',
      },
      
    }}
  />
);

export default GlobalStylesComponent;
