import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const encryptData = (data: string) => {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

export const decryptData = (ciphertext: string) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return bytes?.toString(CryptoJS.enc.Utf8);
};


export const setSessionStorageItem = (key: string, value: string) => {
  const encryptedValue = encryptData(value);
  sessionStorage.setItem(key, encryptedValue);
};

export const getSessionStorageItem = (key: string) => {
  const encryptedValue = sessionStorage.getItem(key);
  if (encryptedValue) {
    return decryptData(encryptedValue);
  }
  return null;
};
