import { combineReducers, createStore } from 'redux';
import { authActionReducer } from './authentication/authReducer';
import { domainReducer } from './domainData/domainReducer';
import { loadingActionReducer } from './loader/loadingReducer';
import { notificationReducer } from './notification/notificationReducer';
import { loadingUiReducer } from './ui/uiReducer';
import { profileReducer } from './profile/profileReducer';

export const rootReducer = combineReducers({
  authentication: authActionReducer,
  domainData: domainReducer,
  loaders: loadingActionReducer,
  notification: notificationReducer,
  ui:loadingUiReducer,
  profile:profileReducer
});

export const store = createStore(rootReducer);