/* eslint-disable prettier/prettier */
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLogout } from './utils/logout';
import { makeStyles } from '@material-ui/core';

import NoDomainsImg from '../assets/icons/svg/noDomainsImg.svg';
import CustomButton from '../components/common/customButton';
import CONSTANTS from '../constants/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const NoDomainsOnboarded: React.FC = () => {
  const classes = useStyles();

  return (
    <Box id="noDomainsOnboardedPage" className={classes.container}>
      <img src={NoDomainsImg} alt="noDomainsImage" />
      <Typography>
        Waiting for domains to get onboarded. Please try again and later.
      </Typography>
      <CustomButton id={'logoutBtn'} label={CONSTANTS.LOGOUT} onClick={useLogout()} />
    </Box>
  );
};

export default NoDomainsOnboarded;
