/* eslint-disable prettier/prettier */
import { makeStyles } from '@material-ui/core';
import {
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const generateMediaQueryStyles = (property, value) => {

  const propertyValue = `${value}rem`;
  const breakpoints = [
    { name: 'xsmall', min: 320, max: 575, factor: 0.6 },
    { name: 'small', min: 576, max: 767, factor: 0.65 },
    { name: 'medium', min: 768, max: 991, factor: 0.7 },
    { name: 'large', min: 992, max: 1199, factor: 0.75 },
    { name: 'xlarge', min: 1200, max: 1399, factor: 0.85 },
    { name: 'xxlarge', min: 1400, max: 1599, factor: 0.95 },
    { name: 'xxxlarge', min: 1600, max: 1799, factor: 1 },
    { name: 'xxxxlarge', min: 1800, max: 1999, factor: 1.1 },
    { name: 'xxxxxlarge', min: 2000, max: 2199, factor: 1.2 },
    { name: 'xxxxxxlarge', min: 2200, max: 2399, factor: 1.3 },
    { name: 'xxxxxxxlarge', min: 2400, max: 2599, factor: 1.4 },
    { name: 'xxxxxxxxlarge', min: 2600, max: 2799, factor: 1.5 },
    { name: 'xxxxxxxxxlarge', min: 2800, max: 2999, factor: 1.6 },
    { name: 'xxxxxxxxxxlarge', min: 3000, max: 3199, factor: 1.7 },
    { name: 'xxxxxxxxxxxlarge', min: 3200, max: 3499, factor: 1.8 },
    { name: 'xxxxxxxxxxxxlarge', min: 3500, max: 3799, factor: 1.9 },
    { name: 'xxxxxxxxxxxxxlarge', min: 3800, max: 4000, factor: 2 },
    { name: 'xxxxxxxxxxxxxxlarge', min: 3800, max: '', factor: 2 },
  ];

  let css = '';

  breakpoints.forEach((breakpoint) => {
    css += `@media only screen and (min-width:${breakpoint.min}px) and (max-width:${breakpoint.max}px) {
                ${property}: calc(${propertyValue} * ${breakpoint.factor}) !important;
            }`;
  });

  return css;
};

export const StyledMuiIcon = (IconComponent) =>
  styled(IconComponent)(() => ({
    fontSize: '1.5rem !important',
    '@media (min-width:2560px)': {
      fontSize: '3rem !important',
    },
    '@media (min-width:3200px)': {
      fontSize: '3.5rem !important',
    },
  }));



export const StyledImg = styled('img')(() => ({
  width: '1.35rem !important',
  '@media (min-width:2560px)': {
    width: '3rem !important',
  },
  '@media (min-width:3200px)': {
    width: '3.5rem !important',
  },
}));


export const CustomFormLabel = styled(Typography)`
  line-height: 100%;
  font-weight: 400;
  ${generateMediaQueryStyles('font-size', 1.2)}
`;

export const useSharedStyles = makeStyles((theme) => ({
  commonHeight: {
    height: '36px',
    [theme.breakpoints.up('sm')]: { height: '30px' },
    [theme.breakpoints.up('md')]: { height: '32px' },
    [theme.breakpoints.up('lg')]: { height: '34px' },
    [theme.breakpoints.up('xl')]: { height: '40px' },
    [theme.breakpoints.up(1920)]: { height: '42px' },
    [theme.breakpoints.up(2560)]: { height: '48px' },
    [theme.breakpoints.up(3840)]: { height: '50px' }, 
  },
}));