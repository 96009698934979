import * as d3 from 'd3';
import { formatNumber } from '../../utils/formatNumber';
import geoJsonData from '../../../components/initial/geo_map_topo_json.json';
import { getRandomPastelColor } from '../../utils/getColor';
import { ALL_COUNTRY_SHORT_CODES } from '../../../constants/countryCodes';

export const createCountryGroup = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  path: any,
  processedHeatMapData: any,
  startColor: string,
  stopColor: string
) => {
  const countryGroup = svg.append('g').attr('id', 'country-paths-group');

  countryGroup
    .selectAll('path')
    .data(geoJsonData?.features)
    .enter()
    .append('path')
    .attr('d', path)
    .style('fill', (d) => {
      const value = processedHeatMapData[d?.id];
      const maxValue = d3?.max(Object?.values(processedHeatMapData)) || 100;
      const colorScale = d3
        .scaleLinear()
        .domain([0, maxValue])
        .range([startColor, stopColor]);

      return value ? colorScale(value) : '#FFFFFF';
    })
    .style('stroke', '#E4E7EC');
  return countryGroup;
};
export const createLabelBg = (
  label: d3.Selection<SVGGElement, unknown, null, undefined>,
  countryData: any
) => {
  const bgRect = label
    .append('rect')
    .attr('rx', 4)
    .attr('ry', 4)
    .attr('fill', countryData ? '#FFFFFF' : 'transparent')
    .attr('stroke', countryData ? '#E4E7EC' : '')
    .attr('stroke-width', 1)
    .attr('filter', 'url(#custom-shadow)');

  return bgRect;
};
export const createTextElements = (
  label: d3.Selection<SVGGElement, unknown, null, undefined>,
  padding: number,
  data: any,
  countryData: any
) => {
  const countryText = label
    .append('text')
    .attr('x', padding + 22)
    .attr('y', padding + 10)
    .attr('font-size', 10)
    .attr('fill', '#666')
    .text(data?.countryCode === 'XX' ? '' : data?.countryName);

  const countText = label
    .append('text')
    .attr('x', padding + 22)
    .attr('y', padding + 22)
    .attr('font-size', 12)
    .text(countryData ? formatNumber(data?.count) : '');

  return { countryText, countText };
};

export const createFlagGroup = (
  label: d3.Selection<SVGGElement, unknown, null, undefined>,
  padding: number,
  countryData: any
) => {
  const flagGroup = label.append('g').attr('class', 'flag-group');

  flagGroup
    .append('rect')
    .attr('x', padding)
    .attr('y', padding)
    .attr('width', 20)
    .attr('height', 20)
    .attr('rx', 2)
    .attr('ry', 2)
    .attr('fill', countryData ? getRandomPastelColor() : 'none');

  flagGroup
    .append('text')
    .attr('x', padding + 10)
    .attr('y', padding + 14)
    .attr('font-size', 12)
    .attr('text-anchor', 'middle')
    .text(countryData?.flag ?? '');

  return flagGroup;
};

export const createLabelPathElement = (
  label: d3.Selection<SVGGElement, unknown, null, undefined>,
  maxWidth: any,
  padding: any,
  countryData: any
) => {
  label
    .append('path')
    .attr(
      'd',
      `M${(maxWidth + 28 + padding * 2) / 2 - 4},${28 + padding * 2} L${
        (maxWidth + 28 + padding * 2) / 2 + 4
      },${28 + padding * 2} L${(maxWidth + 28 + padding * 2) / 2},${
        32 + padding * 2
      } Z`
    )
    .attr('fill', '#FFFFFF')
    .attr('stroke', '#E4E7EC')
    .attr('stroke-width', 1);

  label
    .append('line')
    .attr('x1', (maxWidth + 28 + padding * 2) / 2)
    .attr('y1', 32 + padding * 2)
    .attr('x2', (maxWidth + 28 + padding * 2) / 2)
    .attr('y2', 50 + padding * 2)
    .attr('stroke', countryData ? '#999' : '')
    .attr('stroke-width', 1);

  label
    .append('rect')
    .attr('width', maxWidth + 28 + padding * 2)
    .attr('height', 32 + padding * 2)
    .attr('fill', 'transparent')
    .style('cursor', 'pointer');

  let originalTransform;

  label
    .on('mouseenter', (event) => {
      const hoveredLabel = d3.select(event?.currentTarget);
      originalTransform = hoveredLabel.attr('transform');
      hoveredLabel?.raise();
      const scaledTransform = `${originalTransform} scale(1.1)`;
      hoveredLabel
        .transition()
        .duration(200)
        .attr('transform', scaledTransform);
    })
    .on('mouseleave', (event) => {
      const hoveredLabel = d3.select(event?.currentTarget);
      hoveredLabel
        .transition()
        .duration(200)
        .attr('transform', originalTransform);
    });

  return label;
};

export const labelSvgShadow = (svg: any) => {
  const defs = svg.append('defs');
  const filter = defs
    .append('filter')
    .attr('id', 'custom-shadow')
    .attr('x', '-50%')
    .attr('y', '-50%')
    .attr('width', '200%')
    .attr('height', '200%');

  filter
    .append('feDropShadow')
    .attr('dx', '6.33')
    .attr('dy', '7.69')
    .attr('stdDeviation', '4.52')
    .attr('flood-color', '#7090B0')
    .attr('flood-opacity', '0.6');

  filter
    .append('feDropShadow')
    .attr('dx', '0')
    .attr('dy', '4.52')
    .attr('stdDeviation', '4.52')
    .attr('flood-color', '#000000')
    .attr('flood-opacity', '0.2');
};

export const createCountryLabelGroup = (svg: any, path: any, heatMapData: any) => {
  const labelGroup = svg.append('g').attr('id', 'label-group');

  labelSvgShadow(svg);

  labelGroup
    .selectAll('.country-label')
    .data(heatMapData)
    .enter()
    .append('g')
    .attr('class', 'country-label')
    .attr('transform', (d) => {
      const feature = geoJsonData?.features?.find(
        (f) => f?.id === d?.countryCode
      );
      if (feature) {
        const centroid = path?.centroid(feature);
        return `translate(${centroid?.[0] - 30}, ${centroid?.[1] - 50})`;
      }
      return '';
    })
    .each(function (d, i, nodes) {
      const label = d3.select(nodes[i]);
      const padding = 2;
      const countryData = ALL_COUNTRY_SHORT_CODES?.find(
        (c) => c?.cca2 === d?.countryCode
      );

      const bgRect = createLabelBg(label, countryData);

      createFlagGroup(label, padding, countryData);

      const { countryText, countText } = createTextElements(
        label,
        padding,
        d,
        countryData
      );

      const countryWidth = countryText?.node()?.getComputedTextLength();
      const countWidth = countText?.node()?.getComputedTextLength();
      const maxWidth = Math.max(countryWidth, countWidth);

      bgRect
        .attr('width', maxWidth + 28 + padding * 2)
        .attr('height', 28 + padding * 2);
      createLabelPathElement(label, maxWidth, padding, countryData);
    });

    return labelGroup
};
