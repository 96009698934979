/* eslint-disable prettier/prettier */
import { Box, Chip, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import '../../styles/DashboardStyles.css';
import CustomSelect from '../../components/common/customSelect';
import { makeStyles } from '@material-ui/core';

interface PlaceholderComponentProps {
  filterValue?: string;
  text: string;
  height?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
  children: any;
  padding?: any;
  filterOptions?: any;
  marginBottom?: any;
  isChipPresent?: boolean;
  setFilterValue?: any;
  loading?: boolean;
  id: string;
  boxShadow?: string;
  isFetchingData?: boolean;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: '0.5rem !important',
  },
  boxContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: '0.5rem',
    overflow: 'hidden',
  },
  titleBox: {
    paddingTop: theme.spacing(1.2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  title: {
    paddingLeft: theme.spacing(1.5),
    color: '#1B1B29 !important',
  },
  chip: {
    background: '#FF4B5529',
    color: '#FF4B55',
    marginLeft: theme.spacing(1),
  },
  description: {
    paddingLeft: 2,
    color: '#696969',
    fontSize: '0.8rem',
    '@media (min-width:2560px)': {
      fontSize: '1.5rem',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.8rem',
    },
  },
}));
export const PlaceholderComponent: React.FC<PlaceholderComponentProps> = ({
  id,
  loading = false,
  isChipPresent = false,
  setFilterValue,
  marginBottom = 2,
  boxShadow = '0px 4px 20px 0px #4A556833',
  isFetchingData = false,
  description = '',
  filterValue,
  text,
  height,
  children,
  padding = 2.4,
  filterOptions = [],
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const boxStyle = {
    boxShadow,
    height: { ...height },
    padding,
  };

  const skeletonStyle = {
    height: { ...height },
  };

  const titleBoxStyle = {
    mb: marginBottom,
  };

  const handleFilterChange = (event) => {
    const value = event?.target?.value;
    setFilterValue(value);
  };
  if (loading && !isFetchingData) {
    return (
      <Skeleton
        id={`${id}-skeleton`}
        variant="rectangular"
        width="100%"
        className={classes.skeleton}
        sx={skeletonStyle}
        animation="wave"
      />
    );
  }

  return (
    <Box
      id={id}
      className={classes.boxContainer}
      sx={boxStyle}
    >
      <Box
        id={`${id}-titleBox`}
        sx={titleBoxStyle}
        className={classes.titleBox}
      >
        <Typography
          id={`${id}-title`}
          className={classes.title}
          variant="h2"
          gutterBottom
        >
          {text}
          {description ? (
            <span className={classes.description}> ({description})</span>
          ) : (
            ''
          )}
          {isChipPresent && (
            <Chip label="Blocked" size="small" className={classes.chip} />
          )}
        </Typography>
        {filterOptions?.length > 0 && (
          <CustomSelect
            id={`${id}-customFilter`}
            options={filterOptions}
            width={isSmallScreen?'50%':'20%'}
            height={35}
            color={'#0046FF'}
            value={filterValue}
            onChange={handleFilterChange}
          />
        )}
      </Box>

      {children}
    </Box>
  );
};
