export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

// Action Type

export const LOADER = 'LOADER';
export const IS_WAF_ADMIN = 'IS_WAF_ADMIN';

// new dashboard

export const MENUS_LIST = 'MENUS_LIST';

export const DOMAIN_URLS_BY_CLIENT = 'DOMAIN_URLS_BY_CLIENT';

export const DOMAIN_OBJECT = 'DOMAIN_OBJECT';
export const ATTACK_TRENDLINE_DATA = 'ATTACK_TRENDLINE_DATA';
export const ATTACKS_INFORMATION_TABLE = 'ATTACKS_INFORMATION_TABLE';

export const START_AND_END_DATE_TIME = 'START_AND_END_DATE_TIME';

export const DURATION_DATA = 'DURATION_DATA';

export const REFRESH_PAGE = 'REFRESH_PAGE';

export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_PREFERENCES = 'USER_PREFERENCES';

// loading constants for each widget

export const GEO_LOCATION_LOADER = 'GEO_LOCATION_LOADER';
export const ATTACK_TRENDLINE_LOADER = 'ATTACK_TRENDLINE_LOADER';
export const ATTACKS_INFORMATION_LOADER = 'ATTACKS_INFORMATION_LOADER';

export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';

export const SET_COMBINED_RESPONSE = 'SET_COMBINED_RESPONSE';

export const SET_COMBINED_LOADER = 'SET_COMBINED_LOADER';
