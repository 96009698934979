import CONSTANTS from '../../constants/constants';

export const toCamelCase = (str) => {
  if (typeof str !== CONSTANTS.STRING_TYPE) {
    return str;
  }
  return str?.replace(/_([a-z])/g, (_, letter) => letter?.toUpperCase());
};

export const convertKeysToCamelCase = (data) => {
  if (Array?.isArray(data)) {
    return data?.map((item) => convertKeysToCamelCase(item));
  } else if (data && typeof data === CONSTANTS.OBJECT_TYPE) {
    return Object?.fromEntries(
      Object?.entries(data)?.map(([key, value]) => [toCamelCase(key), value])
    );
  }
  return data;
};
