/* eslint-disable prettier/prettier */
import React from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { handleCrsApis } from '../../../services/crsServices';
import ReusableForm from '../../../components/common/customForm';
import { handleErrorNotification, handleSuccessNotification } from '../../utils/notificationHelpers';

const validationSchema = Yup.object({
  ruleId: Yup.number()
    .typeError('Rule ID must be a number')
    .required('Rule ID is required'),
  category: Yup.string().required('Category is required'),
  message: Yup.string(),
  severity: Yup.string().required('Severity is required'),
  additional: Yup.object({
    impact: Yup.string(),
    recommendation: Yup.string(),
  }),
});

interface AddOrEditRuleProps {
  open: boolean;
  onClose: () => void;
  onSave: any;
  rule?: any;
  isAdding?: boolean;
  fetchCrsRules?:any;
  selectedRule?: any;
}

const AddOrEditRuleComponent: React.FC<AddOrEditRuleProps> = ({
  open,
  onClose,
  rule,
  onSave,
  isAdding = false,
  fetchCrsRules,
  selectedRule,
}) => {
  const dispatch = useDispatch();

  const initialValues = rule || {
    ruleId: '',
    category: '',
    message: '',
    severity: '',
    additional: {
      impact: '',
      recommendation: '',
    },
  };

  const handleSubmit = async (values, { setSubmitting }) => {
        
    const updatedValues = {
      ...values,
      rule_id: parseInt(values?.ruleId),
      ruleId:undefined,
    };
    delete updatedValues?.ruleId;

    const actionType = isAdding ? 'add' : 'update';

    const response: any = await handleCrsApis(
      actionType,
      updatedValues,
      selectedRule?.ruleId
    );

    if (response?.success) {
      handleSuccessNotification(dispatch,response?.message)
      fetchCrsRules();
      onSave(response);
    } else {
      handleErrorNotification(dispatch,response?.message)
    }
    setSubmitting(false);
  };

  const fields = [
    {
      name: 'ruleId',
      label: 'Rule ID',
      type: 'text',
      disabled: !isAdding,
      required: true,
    },
    { name: 'category', label: 'Category', type: 'text', required: true },
    { name: 'message', label: 'Message', type: 'text',required: true },
    { name: 'severity', label: 'Severity', type: 'text', required: true },
    { name: 'additional.impact', label: 'Impact', type: 'text' },
    {
      name: 'additional.recommendation',
      label: 'Recommendation',
      type: 'text',
    },
  ];

  return (
    <ReusableForm
      id={`crsRuleForm${isAdding ? 'Add' : 'Edit'}`}
      open={open}
      onClose={onClose}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      fields={fields}
      title={isAdding ? 'Add New Rule' : 'Edit Rule'}
      buttonLabel={isAdding ? 'Add' : 'Save'}
    />
  );
};

export default AddOrEditRuleComponent;
