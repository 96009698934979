/* eslint-disable prettier/prettier */
import CONSTANTS from '../constants/constants';
import {
  ADD_CRS_RULE_ENDPOINT,
  DELETE_CRS_RULE_ENDPOINT,
  GET_CRS_RULES_ENDPOINT,
  UPDATE_CRS_RULE_ENDPOINT,
  UPLOAD_CRS_RULES_ENDPOINT,
} from '../constants/endpoints';
import { getSessionStorageItem } from '../encrypt';
import { request } from './request';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAllCrsRules = async () => {
  try {
    const response: any = await request.get(
      `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${GET_CRS_RULES_ENDPOINT}`
    );
    if (response?.success) {
      const updatedRules = response?.data?.rules?.map((rule: any) => {
        return {
          ...rule,
          ruleId: rule?.rule_id,
        };
      })

      return { success: true, 
                data: { rules: updatedRules, 
                totalRulesCount: response?.total_rules_count } };
    }
    return response;
  } catch (error) {
    return null; 
  }
};
const ACTIONS={
  ADD:'add',
  UPDATE:'update',
  DELETE:'delete'
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleCrsApis = async (actionType: string, ruleData: any, ruleId: any) => {
  try {
    switch (actionType) {
      case ACTIONS.ADD:{
        const addResponse = await request.post(
          `${getSessionStorageItem(CONSTANTS.GATEWAY_URL)}${ADD_CRS_RULE_ENDPOINT}`,
          ruleData
        );
        return addResponse;
      }
      case ACTIONS.UPDATE:{
        const updateResponse = await request.put(
          `${getSessionStorageItem(
            CONSTANTS.GATEWAY_URL
          )}${UPDATE_CRS_RULE_ENDPOINT}/${ruleData?.rule_id}`,
          ruleData
        );
        return updateResponse;
      }
      case ACTIONS.DELETE:{
        const deleteResponse = await request.delete(
          `${getSessionStorageItem(
            CONSTANTS.GATEWAY_URL
          )}${DELETE_CRS_RULE_ENDPOINT}/${ruleId}`
        );
        return deleteResponse;
      }
      default:
        throw new Error(CONSTANTS.INVALID_ACTION_TYPE_MSG);
    }
  } catch (error: any) {
    return { success: false, error: `${CONSTANTS.FAILED_TO_PERFORM_ACTION_MSG} ${error}` };
  }
};

export const uploadCrsRules = async (crsFile) => {
  try {
    const params = {
      file: crsFile,
    };
    const response: any = await request.postForm(
      `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${UPLOAD_CRS_RULES_ENDPOINT}`,
      params
    );
    if (response?.success) {
      return response;
    }
    return response;
  } catch (error) {
    return null; 
  }
};
