/* eslint-disable prettier/prettier */
import React from 'react';
import { Box, Typography } from '@mui/material';
import AboutPage from './AboutPage';
import CompanyPage from './CompanyPage';
import WafSubscriptionPage from './WafSubscriptionPage';
import DomainsPage from './DomainsPage';
import { makeStyles } from '@material-ui/core';
import { convertKeysToCamelCase } from '../utils/caseConversion';

interface ProfileDetailsProps {
  id: string;
  eachTab: any;
}

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    width: '100%',
    boxShadow: '0px 4px 20px 0px #4A556833',
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF',
  },
  tabTitle: {
    color: '#000000',
    lineHeight: '2rem !important',
    fontSize: '1.3rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.8rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '2rem',
    },
  },
  tabDescription: {
    color: '#7d8ba1 !important',
    fontSize: '0.8rem !important',
    lineHeight: '2.5rem !important',
    '@media (min-width:2560px)': {
      fontSize: '1.35rem !important',
    },
    '@media (min-width:3840px)': {
      fontSize: '1.5rem',
    },
  },
}));

const renderComponent = (tabData) => {
  const updatedTabData = convertKeysToCamelCase(tabData?.data);

  switch (tabData?.label) {
    case 'About':
      return <AboutPage id="about" data={updatedTabData} />;
    case 'Company':
      return <CompanyPage id="company" data={updatedTabData} />;
    case 'Waf Subscription plan':
      return <WafSubscriptionPage id="wafSubscription" data={tabData?.data} />;
    case 'Domains':
      return <DomainsPage id="userDomains" data={tabData?.data} />;
    default:
      return null;
  }
};

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ id, eachTab }) => {

  const classes = useStyles();
  return (
    <Box id={id} className={classes.tabsContainer}>
      <Typography className={classes.tabTitle} id={`${id}-title`} variant="h1">
        {eachTab?.title}
      </Typography>
      <Typography
        className={classes.tabDescription}
        id={`${id}-description`}
        variant="body2"
      >
        {eachTab?.description}
      </Typography>
      {renderComponent(eachTab)}
    </Box>
  );
};

export default ProfileDetails;
