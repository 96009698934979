import {
  DURATION_DATA,
  ATTACKS_INFORMATION_TABLE,
  ATTACK_TRENDLINE_DATA,
  MENUS_LIST,
  START_AND_END_DATE_TIME,
  SET_COMBINED_RESPONSE,
} from '../../constants/actions';

export const setDurationData = (durationData) => {
  return {
    type: DURATION_DATA,
    payload: durationData,
  };
};

export const setStartAndEndDateTime = (startAndEndDateTime) => {
  return {
    type: START_AND_END_DATE_TIME,
    payload: startAndEndDateTime,
  };
};

export const setAttacksTrendlineData = (attacksTrendlineData) => {
  return {
    type: ATTACK_TRENDLINE_DATA,
    payload: attacksTrendlineData,
  };
};

export const setAttackInformationData = (attackInformationData) => {
  return {
    type: ATTACKS_INFORMATION_TABLE,
    payload: attackInformationData,
  };
};

export const setMenusList = (menusList) => {
  return {
    type: MENUS_LIST,
    payload: menusList,
  };
};

export const setCombinedResponse = (combinedResponse) => {
  return {
    type: SET_COMBINED_RESPONSE,
    payload: combinedResponse,
  };
};
