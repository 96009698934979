/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useSharedStyles } from '../../../styles/reuseStyles';

interface ButtonStyleProps {
  backgroundColor?: string;
  textColor?: string;
  textDecoration?:
    | 'none'
    | 'underline'
    | 'line-through'
    | 'initial'
    | 'inherit';
  padding?: string;
  margin?: string;
  borderColor?: string;
  width?:string;
}
const useStyles = makeStyles((theme) => ({
  button: (props: ButtonStyleProps) => ({
    cursor: 'pointer',
    margin: `${props?.margin ?? '0rem 0.2rem'} !important`,
    backgroundColor: `${props?.backgroundColor ?? '#0046FF'} !important`,
    fontSize: '1rem !important', 
    padding: `${props?.padding ?? '0.4rem 1.2rem'} !important`,
    boxShadow: 'none !important',
    borderColor: `${props?.borderColor ?? 'none'} !important`,
    textDecoration: `${props?.textDecoration ?? 'none'} !important`,
    color: `${props?.textColor ?? '#FFFFFF'} !important`,
    '&:disabled': {
      backgroundColor: '#cccccc !important', 
      color: '#666666 !important',
    },
    '&.MuiButton-root': {
      lineHeight: 1,
      [theme.breakpoints.down(600)]: {
        width: `${props?.width ?? '0%'}`,
      }
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem !important',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.95rem !important',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.2rem !important',
    },
    [theme.breakpoints.up(1920)]: {
      fontSize: '1.3rem !important',
      
    },
    [theme.breakpoints.up(2560)]: {
      fontSize: '1.6rem !important',
      padding: '0.25rem 1.2rem !important',
    },
    [theme.breakpoints.up(3840)]: {
      fontSize: '2rem !important',
    },
  }),
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '1.8rem',
    width: '100%',
    [theme.breakpoints.up(1920)]: {
      height: '2.1rem',
    },
    [theme.breakpoints.up(2560)]: {
      height: '2.5rem',
    },
  },
}));

interface CustomButtonProps extends ButtonProps, ButtonStyleProps {
  id: string;
  label: string; 
  onClick?: any;
  variant?: 'contained' | 'outlined' | 'text';
  disabled?: boolean;
  startIcon?: React.ReactNode;
  isLoading?: boolean;
  fontWeight?:number | string
}

const CustomButton: React.FC<CustomButtonProps> = ({
  id,
  isLoading = false,
  borderColor,
  startIcon,
  disabled = false,
  margin,
  label,
  backgroundColor,
  padding,
  textColor,
  textDecoration = 'none',
  variant = 'contained',
  onClick,
  fontWeight,
  width,
  ...props
}) => {
  const classes = useStyles({
    backgroundColor,
    borderColor,
    padding,
    margin,
    textColor,
    textDecoration,
    width
  }); 

  const sharedClasses = useSharedStyles();

  const buttonStyles = {
    fontWeight:fontWeight ?? 400,
  }

  return (
    <Button
      id={id}
      disabled={disabled}
      startIcon={startIcon}
      variant={variant}
      size="small"
      sx={buttonStyles}
      className={`${classes.button} ${sharedClasses.commonHeight}`}
      onClick={onClick}
      {...props}
      data-testid={id}
    >
      {isLoading ? (
        <span className={classes.loadingContainer}>
          <div data-testid="custom-button-dots-loader" className="dots_loader"></div>
        </span>
      ) : (
        label
      )}
    </Button>
  );
};

export default CustomButton;
