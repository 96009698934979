import { useState, useEffect } from 'react';

export const config = [
  { breakpoint: 600, values: { width: 90, height: 90, outerRadius: 90 } },
  { breakpoint: 960, values: { width: 100, height: 100, outerRadius: 100 } },
  { breakpoint: 1100, values: { width: 95, height: 95, outerRadius: 120 } },
  { breakpoint: 1280, values: { width: 120, height: 120, outerRadius: 150 } },
  { breakpoint: 1920, values: { width: 130, height: 130, outerRadius: 170 } },
  { breakpoint: 2560, values: { width: 140, height: 140, outerRadius: 190 } },
  { breakpoint: Infinity, values: { width: 150, height: 150, outerRadius: 200 } },
];

export const getDimensions = (width, config) => {
  for (const { breakpoint, values } of config) {
    if (width < breakpoint) {
      return values;
    }
  }
  return config[config?.length - 1]?.values;
};

const useResponsiveDimensions = () => {
  const [dimensions, setDimensions] = useState(getDimensions(window?.innerWidth, config));

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions(window?.innerWidth, config));
    };

    window?.addEventListener('resize', handleResize);

    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
};

export default useResponsiveDimensions;
