import CONSTANTS from '../../constants/constants';
import { pushNotification } from '../../redux/notification/notificationActions';

export const handleErrorNotification = (dispatch, message) => {
  dispatch(
    pushNotification({
      isOpen: true,
      message,
      type: CONSTANTS.ERROR,
    })
  );
};

export const handleSuccessNotification = (dispatch, message) => {
  dispatch(
    pushNotification({
      isOpen: true,
      message,
      type: CONSTANTS.SUCCESS,
    })
  );
};
