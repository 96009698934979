import React, { useState, useEffect } from 'react';
import EulaModal from './EulaModal';
import CONSTANTS from '../../constants/constants';
import {
  EULA_CHECK_ENDPOINT,
  EULA_SET_ENDPOINT,
  NO_DOMAINS_ONBOARDED_ENDPOINT,
} from '../../constants/endpoints';
import { request } from '../../services/request';
import { useLogout } from '../../screen/utils/logout';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoDomainsOnboarded from '../../screen/NoDomainsOnboarded';
import '../../styles/EulaStyles.css';
import { getSessionStorageItem, setSessionStorageItem } from '../../encrypt';
import {
  handleErrorNotification,
  handleSuccessNotification,
} from '../../screen/utils/notificationHelpers';

interface EulaWrapperProps {
  children: (props: { showContent: boolean }) => React.ReactNode;
}

const EulaWrapper: React.FC<EulaWrapperProps> = ({ children }) => {
  const [showEula, setShowEula] = useState(false);
  const [isNoDomains, setIsNoDomains] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  const userId = getSessionStorageItem(CONSTANTS.AWGMENT_USER_ID);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleLogout = useLogout();

  const handleEulaSuccess = (isAcceptedEula) => {
    if (isAcceptedEula) {
      setShowLoader(false);
      setSessionStorageItem(
        CONSTANTS.EULA_ACCEPTANCE,
        isAcceptedEula.toString()
      );
    } else {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 0.0001);
    }
    setShowEula(!isAcceptedEula);
  };

  const fetchEulaStatusFromApi = async () => {
    const eulaCheckApiUrl = `${getSessionStorageItem(
      CONSTANTS.GATEWAY_URL
    )}${EULA_CHECK_ENDPOINT}`;
    const response: any = await request.get(eulaCheckApiUrl);

    if (response?.success) {
      handleEulaSuccess(response?.data?.is_accepted_eula);
    } else {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 0.0001);
      setIsNoDomains(!isNoDomains);
    }
  };

  const handleEulaStatusFromStorage = (storedEulaStatus) => {
    if (JSON.parse(storedEulaStatus)) {
      setShowLoader(false);

      setShowEula(!JSON.parse(storedEulaStatus));
    } else {
      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 0.0001);

      setShowEula(JSON.parse(storedEulaStatus));
    }
  };

  const checkEulaStatus = async () => {
    try {
      const storedEulaStatus = getSessionStorageItem(CONSTANTS.EULA_ACCEPTANCE);

      if (storedEulaStatus === null) {
        await fetchEulaStatusFromApi();
      } else {
        
        handleEulaStatusFromStorage(storedEulaStatus);
      }
    } catch (error) {
      handleLogout();
    }
  };
  useEffect(() => {
    if (getSessionStorageItem(CONSTANTS.REACT_TOKEN)) {
      checkEulaStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptSuccess = () => {
    setShowEula(false);
    setSessionStorageItem(CONSTANTS.EULA_ACCEPTANCE, CONSTANTS.TRUE_TEXT);
    handleSuccessNotification(dispatch, CONSTANTS.UPDATED_EULA_ACCEPTANCE_MSG);
  };

  const handleAcceptFailure = (message) => {
    if (
      message?.includes(CONSTANTS.MAPPING_FOR_USER_ID) &&
      message?.includes(CONSTANTS.NOT_FOUND)
    ) {
      setShowEula(false);
      setIsNoDomains(!isNoDomains);
      navigate(
        `${CONSTANTS.NAVIGATE_TO_DASHBOARD}${NO_DOMAINS_ONBOARDED_ENDPOINT}`
      )
    } else {
      handleErrorNotification(dispatch, CONSTANTS.FAILED_EULA_MSG);
    }
  };

  const handleAcceptEula = async () => {
    try {
      const EULA_ACCEPT_URL = `${getSessionStorageItem(
        CONSTANTS.GATEWAY_URL
      )}${EULA_SET_ENDPOINT}${userId}`;

      const data = {
        is_accepted_eula: true,
      };
      const response: any = await request.put(EULA_ACCEPT_URL, data);
      response?.success
        ? handleAcceptSuccess()
        : handleAcceptFailure(response?.message);
    } catch (error) {
      handleLogout();
    }
  };

  const handleRejectEula = useLogout();

  const userType =
    getSessionStorageItem(CONSTANTS.USER_TYPE) === CONSTANTS.WAF_ADMIN;

  const showContent = !showEula && !showLoader;

  return (
    <div>
      {isNoDomains && !userType ? (
        <NoDomainsOnboarded />
      ) : (
        <>
          {showLoader && (
            <div className="eula_loader-container">
              <div data-testid="eula-loader" className="eula_loader"></div>
            </div>
          )}
          {showEula && !showLoader && (
            <EulaModal
              isOpen={showEula}
              onAccept={handleAcceptEula}
              onReject={handleRejectEula}
            />
          )}
          {children({ showContent })}
        </>
      )}
    </div>
  );
};

export default EulaWrapper;
