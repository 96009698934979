import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import DetailItems from '../../components/common/profileDetailsReusableComponent';

const useStyles = makeStyles(() => ({
  subscriptionDetails: {
    width: '50%',
  },
}));

interface WafSubscriptionPageProps {
  id: string;
  data: {
    planName: string;
    features: string[];
    cost: string;
    subscriptionStartDate: string;
    subscriptionEndDate: string;
  };
}

const WafSubscriptionPage: React.FC<WafSubscriptionPageProps> = ({ id, data }) => {
  const classes = useStyles();

  const subscriptionData = {
    'Plan name': data?.planName,
    'Features': data?.features,
    'Cost': data?.cost,
    'Subscription start date': data?.subscriptionStartDate,
    'Subscription end date': data?.subscriptionEndDate,
  };

  return (
    <Box id={id} className={classes.subscriptionDetails}>
      <DetailItems data={subscriptionData} />
    </Box>
  );
};

export default WafSubscriptionPage;
