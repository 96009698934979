/* eslint-disable prettier/prettier */
import React from 'react';

import ReusableListComponent from './ReusableListComponent';

interface AttacksByCategoryProps {
  attacksByCategory: any;
}

const AttacksByCategoryComponent: React.FC<AttacksByCategoryProps> = ({ attacksByCategory }) => {
  return (
    <ReusableListComponent
      id="attackCategories"
      data={attacksByCategory}
      leftColumnHeader="Attack Category"
      rightColumnHeader="Count"
      filterLabel="Filter by Category"
      width="100%"
      title={'Attack Categories'}
      showLegendDot={true}
      showSequence={false}
      legendDotColor={'#FB5607'}
    />
  );
};

export default AttacksByCategoryComponent;
